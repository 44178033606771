import React from 'react';
import './SlaveryStatement.css';  // Assuming styles are defined in this CSS file

function ModernSlaveryStatement() {
  return (
    <div className="modern-slavery-statement">
      <h1>Modern Slavery Statement</h1>
      <p>
        Here are the steps Frontier AI has taken and continues to take to understand and minimise the potential risk of modern slavery in its business and supply chains. This statement is published in line with section 54(1) of the Modern Slavery Act 2015.
      </p>

      <h2>About Frontier AI</h2>
      <p>
        Frontier AI is a software company that is seeking to empower administrative staff within healthcare organisations with AI to help them manage their workload.
      </p>

      <h2>Our Commitment to the Principles of the Modern Slavery Act 2015</h2>
      <p>
        Frontier AI is committed to the principles of the Modern Slavery Act 2015 and the abolition of modern slavery and human trafficking.
      </p>
      <p>
        As an equal opportunities employer, we're committed to creating and ensuring a non-discriminatory and respectful working environment for our staff. We want all our staff to feel confident that they can expose wrongdoing without any risk to themselves.
      </p>
      <p>
        Our recruitment and people management processes are designed to ensure that all prospective employees are legally entitled to work in the UK and to safeguard employees from any abuse or coercion.
      </p>
      <p>
        We do not enter into business with any organisation, in the UK or abroad, which knowingly supports or is found to be involved in slavery, servitude, and forced or compulsory labour.
      </p>

      <h2>Our Supply Chain</h2>
      <p>
        Due to the nature of our business, we assess ourselves to have a low risk of modern slavery in our business and supply chains. Our supply chains are limited, and we procure goods and services from a restricted range of suppliers.
      </p>

      <h2>Our Policies in Relation to the Modern Slavery Act 2015</h2>
      <p>The following policies are available to all staff through the intranet:</p>
      <ul>
        <li>Human Rights Policy</li>
        <li>Modern Slavery & Human Trafficking Policy</li>
        <li>Modern Slavery Supplier Policy</li>
      </ul>

      <h2>Embedding the Principles</h2>
      <p>We will continue to embed the principles through:</p>
      <ul>
        <li>Providing awareness training to staff on the Modern Slavery Act 2015 and informing them of the appropriate action to take if they suspect a case of slavery or human trafficking</li>
        <li>Ensuring staff involved in procurement activity are aware of and follow modern slavery procurement guidance on GOV.UK</li>
        <li>Ensuring that consideration of the modern slavery risks and prevention are added to Frontier AI’s policy review process as an employer and procurer of goods and services</li>
        <li>Continuing to take action to embed a zero tolerance policy towards modern slavery</li>
        <li>Ensuring that staff involved in buying or procurement and the recruitment and deployment of workers receive training on modern slavery and ethical employment practices</li>
        <li>Ensuring all staff are aware of our internal policies preventing modern slavery and receive annual training</li>
      </ul>

      <p><strong><i>Signed on behalf of the Supplier:</i></strong></p>
        <div class="signature-section">
    <label for="signer-name"><strong>Name: </strong></label>
    <span id="signer-name">Rachel Finegold</span>
  </div>
  
  <div class="signature-section">
    <label for="signer-title"><strong>Title: </strong></label>
    <span id="signer-title">CEO</span>
  </div>

  <div class="signature-section">
    <label for="signature-date"><strong>Date of Signature: </strong></label>
    <span id="signature-date">27/10/2024</span>
    <p>Rachel Finegold, CEO</p>
  </div>
  <div className="signature-image">
  <img src={`${process.env.PUBLIC_URL}/signature.png`} alt="Signature of CEO" />
</div>
 
    </div>
    
  );
}

export default ModernSlaveryStatement;
