import React from 'react';
import './CarbonPlan.css';

const CarbonPlan = () => {
  return (
    <div className="carbon-plan-container">
      <h1>Carbon Reduction Plan</h1>
      <p><strong>Supplier name:</strong> Frontier AI Limited</p>
      <p><strong>Publication date:</strong> 27/10/2024</p>
      
      <section className="section">
        <h2>Commitment to achieving Net Zero</h2>
        <p>
          Frontier AI is committed to achieving Net Zero emissions by 2040, in line with NHS
          England’s Carbon Footprint targets. Our mission is to support healthcare innovation while
          reducing carbon emissions associated with our operations, AI technology, and workforce.
        </p>
      </section>

      <section className="section">
        <h2>Baseline Emissions Footprint</h2>
        <p>Baseline emissions are a record of the greenhouse gases that have been produced in the
          past and were produced prior to the introduction of any strategies to reduce emissions.
          Baseline emissions are the reference point against which emissions reduction can be
          measured.</p>
        <p><strong>Baseline Year:</strong> 2024</p>
        
        <h3>Additional Details relating to the Baseline Emissions calculations.</h3>
        <p>
          As of the reporting year 2024/25, we will start collecting and publishing our emissions data
          using appropriate tools such as the GHG Protocol and UK Government emissions conversion
          factors. Baseline emissions are essential for tracking carbon reduction. As we are an early-
          stage startup, we will calculate our baseline emissions based on 2024 data.
        </p>
        
        <h3>Baseline year emissions:</h3>
        <table className="emissions-table">
          <thead>
            <tr>
              <th>EMISSIONS</th>
              <th>TOTAL (tCO₂e)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Scope 1</td>
              <td>1.48 (Product Delivery)</td>
            </tr>
            <tr>
              <td>Scope 2</td>
              <td>9.2 (Compute from data centres)</td>
            </tr>
            <tr>
              <td>Scope 3</td>
              <td>9.49 (Business Travel) </td>
            </tr>
            <tr>
              <td><strong>Total Emissions</strong></td>
              <td><strong>20.17</strong></td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="section">
        <h2>Current Emissions Reporting</h2>
        <p>In order to continue our progress to achieving Net Zero, we have adopted the following
          carbon reduction targets.</p>
        <p>We project that carbon emissions will decrease by 60% by 2030 to tCO₂e 8.07 and reach
          Net Zero by 2040. This will be achieved by optimizing cloud computing efficiency, reducing
          workforce commuting through remote work, and implementing sustainable procurement
          practices.</p>
        <ul>
          <li>2026 Target: 30% reduction in emissions compared to baseline</li>
          <li>2030 Target: 60% reduction in emissions</li>
          <li>2040 Target: Net Zero</li>
        </ul>
      </section>

      <section className="section">
        <h2>Carbon Reduction Projects</h2>
        <h3>Completed Carbon Reduction Initiatives</h3>
        <p>
          The following environmental management measures and projects have been initiated since
          the 2024 baseline. The carbon emission reduction achieved by these schemes will equate to
          8.07 tCO₂e, a 60% reduction against the 2024 baseline and the measures will be in effect
          when performing the contract.
        </p>
        <p>We have identified several initiatives to reduce our carbon footprint:</p>
        <ul>
          <li><strong>Cloud Computing</strong>
            <ul>
              <li>Cloud Efficiency: Utilizing cloud services powered by renewable energy, optimizing
                  AI processing workloads to minimize energy use. Cloud-based AI models will reduce
                  the need for on-premises servers, significantly lowering energy consumption.
              </li>
              <li>Carbon-neutral Cloud Providers: Partnering with cloud providers who are
                  committed to achieving carbon neutrality such as Amazon Web Services (AWS) known for
                  their renewable energy initiatives.
              </li>
            </ul>
          </li>
          <li><strong>Digital Transformation</strong>
            <ul>
              <li>Remote Working: Encouraging staff to work remotely, minimizing the need for daily
                  commuting and associated emissions. This is aligned with NHS digital priorities.
              </li>
              <li>Reduction of patient travel: Supporting healthcare organizations to adopt AI-driven
                  digital tools that reduce unnecessary patient transport, thereby cutting carbon
                  emissions related to patient mobility.
              </li>
            </ul>
          </li>
          <li><strong>Energy-efficient Operations</strong>
            <ul>
              <li>Office Energy Management: Implement energy-efficient lighting (LED) and utilize
                  buildings with renewable energy sources.
              </li>
              <li>Electric Vehicles (EVs): Introduce EVs for any necessary business travel, with a
                  long-term goal of 100% EV adoption by 2030.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="section">
        <h2>Future Measures</h2>
        <p>We aim to further reduce our environmental impact by:</p>
        <ul>
          <li>Supplier Engagement: Requiring any key suppliers to have Net Zero targets in
              place by 2030 and tracking their carbon data
          </li>
          <li>AI Energy Optimization: Designing AI systems that are optimized for energy
              efficiency from the ground up, reducing power consumption by at least 30% by 2030.
          </li>
          <li>Carbon Offsetting: Explore carbon offset options such as reforestation projects to
              cover residual emissions that cannot be eliminated directly.
          </li>
        </ul>
      </section>

      <section className="section">
        <h2>Monitoring Progress</h2>
        <p>
          We will regularly report our carbon emissions and progress against targets through public
          reporting mechanisms, following the NHS's guidelines. Carbon data will be reviewed
          quarterly, and strategies will be adjusted as necessary to meet our 2040 Net Zero target.
        </p>
      </section>

      <section className="section">
        <h2>Declaration and Sign Off</h2>
        <p>
          This Carbon Reduction Plan has been completed in accordance with PPN 06/21 and
          associated guidance and reporting standard for Carbon Reduction Plans.
        </p>
        <ul className="sign-off">
          <li>Emissions have been reported and recorded in accordance with the published reporting
              standard for Carbon Reduction Plans and the GHG Reporting Protocol corporate standard
              and uses the appropriate Government emission conversion factors for greenhouse gas
              company reporting.
          </li>
          <li>Scope 1 and Scope 2 emissions have been reported in accordance with SECR
              requirements, and the required subset of Scope 3 emissions have been reported in
              accordance with the published reporting standard for Carbon Reduction Plans and the
              Corporate Value Chain (Scope 3) Standard.
          </li>
        </ul>
        <p>This Carbon Reduction Plan has been reviewed and signed off by the board of directors (or
           equivalent management body).</p>
        <p><strong><i>Signed on behalf of the Supplier:</i></strong></p>
        <div class="signature-section">
    <label for="signer-name"><strong>Name: </strong></label>
    <span id="signer-name">Rachel Finegold</span>
  </div>
  
  <div class="signature-section">
    <label for="signer-title"><strong>Title: </strong></label>
    <span id="signer-title">CEO</span>
  </div>

  <div class="signature-section">
    <label for="signature-date"><strong>Date of Signature: </strong></label>
    <span id="signature-date">27/10/2024</span>
    <p>Rachel Finegold, CEO</p>
  </div>
  <div className="signature-image">
  <img src={`${process.env.PUBLIC_URL}/signature.png`} alt="Signature of CEO" />
</div>
      </section>
    </div>
  );
};

export default CarbonPlan;
