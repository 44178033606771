import React from 'react';
import './ContactForm.css';

function ContactForm() {
  return (
    <div className="contact-form" id="contact-form">
      
      <div className="contact-form-left">
        <h2>Join our waitlist</h2>
        <p>Are you part of a Trust or ICB  and are interested in levelling up services or mitigating staffing  vacancies? Sign up to learn more.</p>
      </div>
      <div className="contact-form-right">
      {/* Form configuration */}
      <form
        action="https://formspree.io/f/xjkbznqj"
        method="POST"
      >
        {/* Honeypot field for spam prevention */}
        <input type="hidden" name="contact" value="contact" />

        <div className="form-group">
          <label>Name</label>
          <input type="text" name="name" placeholder="Your name here" required />
        </div>
        <div className="form-group">
          <label>Organisation</label>
          <input type="text" name="organisation" placeholder="Your organisation name here" required />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input type="email" name="email" placeholder="Your email here" required />
        </div>
        <div className="form-group">
          <label>Your interest in our solution</label>
          <textarea name="message" placeholder="Please provide more details" required></textarea>
        </div>
        <button type="submit" className="submit-button">Send</button>
      </form>
      </div>
    </div>
  );
}

export default ContactForm;
